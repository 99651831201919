@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.radix-themes {
  --default-font-family: "Montserrat", sans-serif;
  --em-font-family: var(--default-font-family);
  --quote-font-family: var(--default-font-family);
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: no-repeat center/cover url("./assets/background-stars.png");
}

@media (min-resolution: 144dpi) {
  body {
    background-image: url("./assets/background-stars@2x.png");
  }
}

@media (min-resolution: 240dpi) {
  body {
    background-image: url("./assets/background-stars@3x.png");
  }
}

@media (min-resolution: 336dpi) {
  body {
    background-image: url("./assets/background-stars@4x.png");
  }
}

#root {
  width: 100%;
  height: 100%;
}

/*
 * take up entire vertical space to allow easier layout
 */

.rt-ContainerInner {
  position: relative;
  height: 100%;
}

/*
 * override "display: table" to allows vertical centering in scroll areas
 */

.rt-ScrollAreaViewport > :first-child {
  min-height: 100%;
  display: flex !important;
  flex-direction: column;
}

/*
 * Display appropriate nav panel for size
 *
 * Break points are at 520px height and 1024px width
 *
 *       | narrow                                   | wide
 * ------+------------------------------------------+-------------------------------------
 * short | [scrolling] [narrow banner (hidden nav)] | [scrolling] [wide banner] [side nav]
 * ------+------------------------------------------+-------------------------------------
 * tall  | [fixed] [narrow banner (hidden nav)]     | [fixed] [wide banner] [top nav]
 */

.staticBanner,
.leftNav,
.wideBanner,
.wideBannerNav {
  display: none;
}

@media (min-width: 1024px) {
  .leftNav {
    display: block;
  }

  .wideBanner {
    display: flex;
  }

  .narrowBanner {
    display: none;
  }
}

@media (min-height: 520px) {
  .scrollingBanner,
  .leftNav {
    display: none;
  }

  .staticBanner {
    display: block;
  }

  /*
   * wide banner nav is a descendant of wideBanner, so will still be hidden if wideBanner is hidden
   */

  .wideBannerNav {
    display: flex;
  }
}

/*
 * Nav accordion animations
 */

.navAccordionContent {
  overflow: hidden;
}

.navAccordionContent[data-state="open"] {
  animation: slideDown 300ms ease-out;
}
.navAccordionContent[data-state="closed"] {
  animation: slideUp 300ms ease-out;
}

.clickable {
  cursor: pointer;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

/*
 * Float layout flex column of small screens float
 */
.floatLayoutRoot {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
}

@media (min-width: 800px) {
  .floatLayoutRoot {
    display: block;
  }

  .floatLayoutRoot > * {
    margin-bottom: var(--space-3);
  }

  .floatLayoutFloat {
    max-width: 25rem;
  }

  .floatLayoutFloat[style*="float: left"] {
    margin-right: var(--space-3);
  }

  .floatLayoutFloat[style*="float: right"] {
    margin-left: var(--space-3);
  }
}
